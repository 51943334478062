import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`Governance of the Materia protocol is available through the `}<a parentName="p" {...{
        "href": "https://dapp.dfohub.com/?addr=0xf056aE03Cf991e4587Da458B2c85e9a353684B3a"
      }}><inlineCode parentName="a">{`DFO Dapp`}</inlineCode></a>{` where Token Holders can vote on proposals to extend the main protocol.`}</p>
    <p>{`Although the Materia core contracts can be used without limitations and should not be replaced soon, new Materia versions may implement new mechanisms that requires the replacement of some other non-core contracts.
Moreover some contracts allow the adjustment of some specific values of the protocol.`}</p>
    <p>{`Since Materia is a `}<a parentName="p" {...{
        "href": "https://www.dfohub.com/"
      }}><inlineCode parentName="a">{`DFO`}</inlineCode></a>{` (Decentralized Flexible Organizations) the GIL holders can create new proposals and then vote for them.`}</p>
    <h2 {...{
      "id": "what-is-a-proposal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-a-proposal",
        "aria-label": "what is a proposal permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is a proposal`}</h2>
    <p>{`In its essence, a proposal is a smart contract that implements methods with specific signatures.
If the proposal expresses a functionality that can be enabled or disabled then it must implement two methods:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function onStart(address, address) public;
function onStop(address) public;
`}</code></pre>
    <p>{`Otherwise, if the proposal express a functionality that creates a one time change in the blockchain (called “One time proposal”) it must implement this method:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function callOneTime(address) public;
`}</code></pre>
    <p>{`Please refer to the DfoHub documentation for more information.`}</p>
    <h1 {...{
      "id": "create-a-proposal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#create-a-proposal",
        "aria-label": "create a proposal permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create a proposal`}</h1>
    <p>{`To create a proposal visit the `}<a parentName="p" {...{
        "href": "https://dapp.dfohub.com/?addr=0xabc"
      }}><inlineCode parentName="a">{`Materia DFO`}</inlineCode>{` page`}</a>{` on DfoHub, select “Governance”, then “New Proposal” and follow the wizard.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      